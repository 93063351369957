// @flow
import type {EnhancedOrder, EnhancedOrderItem, Order} from "../models";
import productService from "../../service/productService";
import reshipmentService from "../../reshipment/api/reshipmentService";
import {ReshipmentStatus} from "../../reshipment/model";
import {OrderStatus} from "../models";

const orderEnhancer = async (order: Order): Promise<EnhancedOrder> => {
	const partNumbers = order.localizedOrderItems.map(item => item.partNumber);
	const detailedProducts = await productService.getDetailedProducts(partNumbers, false, false, false);
	const orderReshipment = await reshipmentService.getReshipmentByOrderNumber(order.orderId);
	const localizedOrderItems: EnhancedOrderItem[] = await Promise.all(
		order.localizedOrderItems.map(async orderItem => {
			const detailedProduct = detailedProducts.find(product => product.partNumber === orderItem.partNumber);
			const name = detailedProduct ? detailedProduct.product.name : "";
			const hasValidBatchPriceForReshipment = await reshipmentService.validateBatchPriceForReshipment(orderItem.batchPrice);

			return {
				...orderItem,
				name,
				hasReshipment: !!orderReshipment && !!orderReshipment.items.find(item =>
					item.partNumber === orderItem.partNumber
				),
				hasValidBatchPriceForReshipment
			};
		})
	);
	return {
		...order,
		items: localizedOrderItems,
		isReshipmentRequested: !!orderReshipment && orderReshipment.status !== ReshipmentStatus.CREATED,
		canBeReshipped: !!orderReshipment
			? orderReshipment.status === ReshipmentStatus.CREATED
			: (order.status === OrderStatus.PLACED || order.status === OrderStatus.PROCESSED || order.status === OrderStatus.SHIPPED)
	};
};

export default orderEnhancer;
