import type Axios from "axios";
import {axiosInstance} from "../../api";
import qs from "query-string";
import type {PartCodeModel} from "../../productsearch/productApi";
import {MatchingMaintenanceGuideTimesResponse} from "../model";
import type {Vehicle} from "../../vehicle/model";
import type {MaintenanceGuideTimeModel} from "../model";
import productApi from "../../productsearch/productApi";

class MaintenanceService {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async getSectionFacetsByVehicle(vehicleModel: string, vehicleModelYear: string) {
		const response = await this.api.get("/maintenance/section/facets",
			{
				params: {
					vehicleModel,
					vehicleModelYear
				}
			}
		);
		return response.data;
	}

	async getCategoryIllustrations(categoryId: number) {
		const response = await this.api.get(`/maintenance/category/${categoryId}/illustrations`);
		return response.data;
	}

	async getFittingsByCategoryAndModuleNumbersAndVehicle(categoryId: number, moduleNumbers: string[], vehicleModel: string, vehicleYear: string) {
		const response = await this.api.get("/maintenance/fitting/findByCategoryAndModulesAndVehicle",
			{
				params: {
					categoryId,
					moduleReferenceNumbers: moduleNumbers,
					vehicleModelYear: vehicleYear,
					vehicleModel
				},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		);
		return response.data;
	}

	async getFittingsByPartCodeAndVehicle(productNumber: string, partCode: PartCodeModel, vehicleModel: string, vehicleYear: string) {
		const response = await this.api.get("/maintenance/fitting/findByPartCodeAndVehicle",
			{
				params: {
					partCode: partCode.partCode,
					partCodeSymbol: partCode.partCodeSymbol,
					vehicleModelYear: vehicleYear,
					vehicleModel
				}
			}
		);
		return response.data;
	}

	async getMaintenanceBundles() {
		const response = await this.api.get("/maintenance/bundle");
		return response.data;
	}

	async getFittingsByOperationsAndVehicle(operationIds: number[], vehicleModel: string, vehicleYear: string): Promise<MatchingMaintenanceGuideTimesResponse> {
		const response = await this.api.get("/maintenance/fitting/findByOperationAndVehicle",
			{
				params: {
					operationIds,
					vehicleModelYear: vehicleYear,
					vehicleModel
				},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		);
		return response.data;
	}

	async getMaintenanceTasksWithEnhancedSpares(vehicle: Vehicle, guideTimeModels: MaintenanceGuideTimeModel[], withExternalStocks: boolean) {
		const factoryCode = vehicle.factoryCode;
		const destinationCode = vehicle.destinationCode;
		const modelYearCode = vehicle.modelYearRestriction;
		const optionCodes = vehicle.optionCodes;
		const colorCode = vehicle.colorCode;
		const interiorColorCode = vehicle.interiorColorCode;
		const vehicleBuildDate = vehicle.productionDate;
		const vehicleDecommissionDate = vehicle.decommissionDate;

		return Promise.all(
			guideTimeModels.map(async guideTimeModel => {
				const productResponse = await productApi.getByPartCodeAndMostRelevantPrecisionDetails(
					guideTimeModel.partCodes,
					factoryCode,
					destinationCode,
					modelYearCode,
					optionCodes,
					colorCode,
					interiorColorCode,
					null,
					vehicleBuildDate,
					vehicleDecommissionDate
				);

				let productDetails = [];
				if (productResponse.content.length > 0) {
					const partNumbers = productResponse.content.map(p => p.partNo);
					productDetails = await productApi.getProductDetails(withExternalStocks, partNumbers);
				}

				const productsWithDetails = productResponse.content.map(product => {
					const details = productDetails.find(detail => product.partNo === detail.partNumber);

					return {
						productNumber: product.partNo,
						name: product.name,
						taskDescription: `${guideTimeModel.taskDescription} ${guideTimeModel.operationActionDescription}`,
						retailPrice: details?.storage?.retailPrice || 0,
						unit: "CHUNK",
						specification: product.specification,
						comment: product.comment,
						amount: product.quantity,
						includesOptionalPart: guideTimeModel.partCodes.find(
							partCode => product.partCode === partCode.partCode && product.partCodeSymbol === partCode.partCodeSymbol
						).includesOptionalPart
					};
				});

				return {
					operationNumber: guideTimeModel.operationNumber,
					description: `${guideTimeModel.taskDescription} ${guideTimeModel.operationActionDescription}`,
					workUnit: guideTimeModel.workUnit,
					spares: productsWithDetails
				};
			})
		);
	}
}

const MaintenanceSectionServiceInstance = new MaintenanceService(axiosInstance);
export default MaintenanceSectionServiceInstance;