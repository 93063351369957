import type {PartCodeModel} from "../../productsearch/productApi";
import type {SparePositionType} from "../../proposal/model";

export type MaintenanceModule = {
	id: number,
	referenceNumber: string
}

export type MaintenanceModuleLink = {
	startX: number,
	startY: number,
	moduleModels: MaintenanceModule[]
}

export type MaintenanceIllustration = {
	id: number,
	name: string,
	imageURI: string,
	moduleLinkModels: MaintenanceModuleLink[]
}

export type MaintenanceGuideTimeModel = {
	workUnit: number,
	comment: ?string,
	taskDescription: string,
	operationNumber: string,
	operationActionDescription: string,
	partCodes: PartCodeModel[]
}

export type CustomMaintenanceGuideTimeModel = {
	operationNumber: string,
	operationActionDescription: string,
	workUnit: number,
	taskDescription: string
}

export type MaintenanceTaskSpare = SparePositionType & {
	specification: string,
	taskDescription: string,
	comment: string,
	quantity: number,
	includesOptionalPart: boolean
}

export type MaintenanceTaskPosition = {
	operationNumber: string,
	description: string,
	workUnit: number,
	spares: MaintenanceTaskSpare[]
}

export type MatchingMaintenanceGuideTimesResponse = {
	maintenanceGuideTimes: MaintenanceGuideTimeModel;
	customMaintenanceGuideTime: CustomMaintenanceGuideTimeModel;
}

export type MaintenanceGuideTimeSearchVehicleParams = {
	modelYear: string,
	type: string
}

export type MaintenanceBundleOperationModel = {
	id: number,
	maintenanceModuleId: number,
	maintenanceCategoryId: number,
	maintenanceSectionId: number,
	moduleReferenceNumber: string,
	maintenanceCategoryOpPrefix: string,
	maintenanceOperationActionOpSuffix: string,
}

export type MaintenanceBundleModel = {
	id: number,
	name: string,
	operations: MaintenanceBundleOperationModel[]
}

export const MaintenanceTaskSearchContext =  Object.freeze({
	TASK_BUNDLE: "TASK_BUNDLE",
	TASK_CATALOG: "TASK_CATALOG"
});