import maintenanceService from "../service/maintenanceService";
import type {MaintenanceGuideTimeModel} from "../model";
import type {Dispatch} from "../../actions";
import {createUuid} from "../../api";
import type {Vehicle} from "../../vehicle/model";

export const FETCH_MAINTENANCE_BUNDLES = {
	REQUEST: "FETCH_MAINTENANCE_BUNDLES_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_BUNDLES_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_BUNDLES_FAILURE"
};
export const FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION = {
	REQUEST: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION_FAILURE",
};
export const FETCH_MAINTENANCE_BUNDLE_TASK_SPARES = {
	REQUEST: "FETCH_MAINTENANCE_BUNDLE_TASK_SPARES_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_BUNDLE_TASK_SPARES_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_BUNDLE_TASK_SPARES_FAILURE"
};
export const RESET_MAINTENANCE_BUNDLE_TASK_POSITIONS = "RESET_MAINTENANCE_BUNDLE_TASK_POSITIONS";
export const RESET_MAINTENANCE_BUNDLE_STATE = "RESET_MAINTENANCE_BUNDLE_STATE";

export const getMaintenanceBundles = () => {
	return async dispatch => {
		dispatch({type: FETCH_MAINTENANCE_BUNDLES.REQUEST});
		try {
			const maintenanceBundles = await maintenanceService.getMaintenanceBundles();
			dispatch({
				type: FETCH_MAINTENANCE_BUNDLES.SUCCESS,
				payload: maintenanceBundles
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_BUNDLES.FAILURE});
			console.error(e);
		}
	};
};

export const getGuideTimeModelsByBundleOperationAndVehicle = (operationIds: number[], vehicleModel: string, vehicleYear: string) => {
	return async dispatch => {
		const requestId = createUuid();
		dispatch({
			type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION.REQUEST,
			payload: requestId
		});
		try {
			const response = await maintenanceService.getFittingsByOperationsAndVehicle(operationIds, vehicleModel, vehicleYear);
			dispatch({
				type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION.SUCCESS,
				payload: response.maintenanceGuideTimes
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION.FAILURE});
			console.error(e);
		}
	};
};


export function fetchProductDetailsForMaintenanceGuideTimes(vehicle: Vehicle, guideTimeModels: MaintenanceGuideTimeModel[], withExternalStocks: boolean) {
	return async (dispatch: Dispatch) => {

		const requestId = createUuid();
		dispatch({
			type: FETCH_MAINTENANCE_BUNDLE_TASK_SPARES.REQUEST,
			payload: requestId
		});

		try {
			const maintenanceTasksWithEnhancedSpares = await maintenanceService.getMaintenanceTasksWithEnhancedSpares(vehicle, guideTimeModels, withExternalStocks);

			dispatch({
				type: FETCH_MAINTENANCE_BUNDLE_TASK_SPARES.SUCCESS,
				payload: {
					maintenanceTaskPositions: maintenanceTasksWithEnhancedSpares,
					requestId
				}
			});

		} catch (error) {
			dispatch({
				type: FETCH_MAINTENANCE_BUNDLE_TASK_SPARES.FAILURE,
				payload: requestId
			});
			console.error(error);
		}
	};
}

export const resetMaintenanceBundleTaskPositions = () => {
	return dispatch => {
		dispatch({
			type: RESET_MAINTENANCE_BUNDLE_TASK_POSITIONS
		});
	};
};

export const resetMaintenanceBundleState = () => {
	return dispatch => {
		dispatch({
			type: RESET_MAINTENANCE_BUNDLE_STATE
		});
	};
};