// @flow

export const OrderType = Object.freeze({
	LIMITED_RUSH: "LIMITED_RUSH",
	UNLIMITED_RUSH: "UNLIMITED_RUSH",
	EXPORT: "EXPORT",
	SPECIAL: "SPECIAL",
	STOCK: "STOCK",
	FIRST_EQUIPMENT: "FIRST_EQUIPMENT",
	EXPORT_NEX_RUSH: "EXPORT_NEX_RUSH",
	INTERAL: "INTERAL",
	SPECIAL_KD: "SPECIAL_KD",
	REARRANGEMENT: "REARRANGEMENT",
	EXPORT_NEX_STOCK: "EXPORT_NEX_STOCK",
	SCRAPPING: "SCRAPPING",
	EXPORT_RUSH: "EXPORT_RUSH",
	SINGLE_PART_RIM: "SINGLE_PART_RIM",
	CAR_DOWN: "CAR_DOWN",
	SINGLE_PART_WHEEL: "SINGLE_PART_WHEEL",
	SINGLE_PART_BATTERY: "SINGLE_PART_BATTERY",
	SINGLE_PART_HUB_CAP: "SINGLE_PART_HUB_CAP",
	SINGLE_PART_HEATING: "SINGLE_PART_HEATING",
	SINGLE_PART_SUMMER: "SINGLE_PART_SUMMER",
	SWISS: "SWISS",
	TWGL_LOCAL_ACCESSORY: "TWGL_LOCAL_ACCESSORY",
	MARKETING_SALES_PROMOTION: "MARKETING_SALES_PROMOTION",
	TWGL_LOCAL_PARTS: "TWGL_LOCAL_PARTS",
	INTRODUCTION_OFFER: "INTRODUCTION_OFFER",
	TWGL_PART_OB: "TWGL_PART_OB",
	SINGLE_PART_AUTUMN: "SINGLE_PART_AUTUMN",
	SINGLE_PART_SALE: "SINGLE_PART_SALE",
	SEVEN_FIVE_SALES_PROMOTION: "SEVEN_FIVE_SALES_PROMOTION",
	CUSTOMER_SERVICE_LIT: "CUSTOMER_SERVICE_LIT",
	WITHOUT_YEAR_BONUS: "WITHOUT_YEAR_BONUS",
	SWISS_GO: "SWISS_GO",
});

export type OfferItem = {
	amount: number,
	currency: string,
	isUnfulfilledOrderProduct?: true,
	isPromotionProduct: boolean,
	productNumber: string,
	name?: string,
	productPrice: number,
	stockDeliveryPrice: number,
	hasStockLimitExceeded: boolean,
	promotionPrice: number
}

export type OfferCondition = {
	minimumProductCount: number,
	minimumArticleAmount: number,
	minimumPromotionPrice: number,
}

export type Offer = {
	condition: OfferCondition,
	name: string,
	deliveryCost: number,
	price: number,
	extraCost: number,
	currency: string,
	orderType: $Keys<typeof OrderType>,
	products: OfferItem[],
	exceedsStockLimit: boolean,
}

export type UnfulfilledOffer = {
	condition: OfferCondition,
	amount: number,
	name: string,
	promotionId: number,
	products: OfferItem[]
}

export type OfferBundle = {
	offers: Offer[],
	unfulfilledOffers: UnfulfilledOffer[],
	price: number,
	currency: string
}

export const OrderStatus = Object.freeze({
	CREATED: "CREATED",
	DUPLICATE: "DUPLICATE",
	DISPOSABLE: "DISPOSABLE",
	DISPOSED: "DISPOSED",
	PLACED: "PLACED",
	PROCESSED: "PROCESSED",
	SHIPPED: "SHIPPED",
	CANCELED: "CANCELED",
});

export type LocalizedOrderItem = {
	salesPromotionId: string,
	orderId: number,
	itemId: number,
	partNumber: string,
	price: number,
	batchPrice: number,
	currency: string,
	orderedQuantity: number,
	disposedQuantity: number,
	localizedOrderItemStatus: string
}

export type Order = {
	orderId: string,
	orderType: $Keys<typeof OrderType>,
	localizedOrderType: string,
	promotionId: string,
	salesPromotionName: string,
	price: number,
	currency: string,
	status: $Values<typeof OrderStatus>,
	localizedOrderStatus: string,
	creationDate: Date,
	localizedOrderItems: LocalizedOrderItem[],
}

export type EnhancedOrderItem = {
	name: ?string,
	hasReshipment: boolean,
	hasValidBatchPriceForReshipment: boolean
} & LocalizedOrderItem;

export type EnhancedOrder = {
	items: EnhancedOrderItem[],
	canBeReshipped: boolean
} & Order;

export type OrderRequestItem = {
	partNumber: string,
	quantity: number,
	price: string,
	note: string,
};

export type CreateOrderRequest = {
	orderTypeId: number,
	salesPromotionId: number,
	orderName: string,
	orderItems: OrderRequestItem[],
};
