import {
	FETCH_MAINTENANCE_BUNDLE_TASK_SPARES,
	FETCH_MAINTENANCE_BUNDLES,
	FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION,
	RESET_MAINTENANCE_BUNDLE_STATE,
	RESET_MAINTENANCE_BUNDLE_TASK_POSITIONS
} from "../actions/maintenanceTaskBundle";
import type {MaintenanceBundleModel, MaintenanceGuideTimeModel} from "../model";
import {MaintenanceTaskPosition} from "../model";

export type MaintenanceBundleState = {
	isFetchingBundles: boolean,
	isFetchingGuideTimeModels: boolean,
	bundles: MaintenanceBundleModel[],
	guideTimeModels: MaintenanceGuideTimeModel[],
	isFetchingMaintenanceTaskSpares: boolean,
	maintenanceTaskPositions: MaintenanceTaskPosition[],
	requestId: string,
}

const initialState: MaintenanceBundleState = {
	isFetchingBundles: false,
	isFetchingGuideTimeModels: false,
	bundles: [],
	guideTimeModels: null,
	isFetchingMaintenanceTaskSpares: false,
	maintenanceTaskPositions: [],
	requestId: null,
};

const maintenanceBundle = (state: MaintenanceBundleState = initialState, action) => {
	switch (action.type) {
		case FETCH_MAINTENANCE_BUNDLES.REQUEST:
			return {
				...state,
				isFetchingBundles: true
			};
		case FETCH_MAINTENANCE_BUNDLES.SUCCESS:
			return {
				...state,
				isFetchingBundles: false,
				bundles: action.payload
			};
		case FETCH_MAINTENANCE_BUNDLES.FAILURE:
			return {
				...state,
				isFetchingBundles: false
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION.REQUEST:
			return {
				...state,
				isFetchingGuideTimeModels: true,
				requestId: action.payload,
				isFetchingMaintenanceTaskSpares: false
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION.SUCCESS:
			return {
				...state,
				isFetchingGuideTimeModels: false,
				guideTimeModels: action.payload
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS_BY_BUNDLE_OPERATION.FAILURE:
			return {
				...state,
				isFetchingGuideTimeModels: false
			};
		case FETCH_MAINTENANCE_BUNDLE_TASK_SPARES.REQUEST:
			return {
				...state,
				requestId: action.payload,
				isFetchingMaintenanceTaskSpares: true
			};
		case FETCH_MAINTENANCE_BUNDLE_TASK_SPARES.SUCCESS:
			return state.requestId === action.payload.requestId
				? {
					...state,
					maintenanceTaskPositions: action.payload.maintenanceTaskPositions,
					isFetchingMaintenanceTaskSpares: false
				}
				: state;
		case FETCH_MAINTENANCE_BUNDLE_TASK_SPARES.FAILURE:
				return {
					...state,
					isFetchingMaintenanceTaskSpares: false
				};
		case RESET_MAINTENANCE_BUNDLE_TASK_POSITIONS:
			return {
				...state,
				maintenanceTaskPositions: [],
			};
		case RESET_MAINTENANCE_BUNDLE_STATE:
			return {
				...state,
				isFetchingBundles: false,
				isFetchingGuideTimeModels: false,
				guideTimeModels: null,
				isFetchingMaintenanceTaskSpares: false,
				maintenanceTaskPositions: [],
				requestId: null
			};
		default:
			return state;
	}
};

export default maintenanceBundle;

