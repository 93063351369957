// @flow
import type {Axios, AxiosPromise} from "axios";
import {axiosInstance} from "../../api";
import type {
	CreateReshipmentRequestModel, DeleteReshipmentItemModel,
	DeleteReshipmentModel,
	ReshipmentModel,
	SendReshipmentRequestModel, SendReshipmentSendModel
} from "../model";
import {ReshipmentStatus, UpdateReshipmentItemRequestModel} from "../model";

class ReshipmentService {
	api: Axios;

	constructor() {
		this.api = axiosInstance;
	}

	createReshipment = (request: CreateReshipmentRequestModel): AxiosPromise<ReshipmentModel> => {
		return this.api.post("/reshipment", {
			...request
		});
	};

	getReshipments = (status?: $Values<typeof ReshipmentStatus>, startDate: Date, endDate: Date): AxiosPromise => {
		return this.api
			.get<string, Array<ReshipmentModel>>(
				"/reshipment/byDealer",
				{
					params: {
						status,
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString(),
					}
				}
			)
			.then(response => response.data.content);
	};

	getReshipmentByOrderNumber = (orderNumber: string): AxiosPromise => {
		return this.api.get<string, Array<ReshipmentModel>>("/reshipment/byOrderNumber", {
			params: {orderNumber}
		})
			.then(response => response.data);
	};

	updateReshipmentItem = (request: UpdateReshipmentItemRequestModel) => {
		return this.api.patch("/reshipment/item/requestedAmount", {
			...request
		})
			.then(response => response.data);
	};

	requestReshipment = (request: SendReshipmentRequestModel) => {
		return this.api.post("/reshipment/request", {
			...request
		})
			.then(response => response.data);
	};
	
	deleteReshipment = (request: DeleteReshipmentModel) => {
		let reshipmentId = request.id;
		return this.api.post(`/reshipment/${reshipmentId}/delete`, {
			...request
		})
			.then(response => response.data);
	};
	
	deleteReshipmentItem = (request: DeleteReshipmentItemModel) => {
		let reshipmentItemId = request.id;
		return this.api.delete(`/reshipment/${reshipmentItemId}/item`, {
			...request
		})
			.then(response => response.data);
	};
	
	sendReshipment = (request: SendReshipmentSendModel) => {
		return this.api.post("/reshipment/send", {
			...request
		})
			.then(response => response.data);
	};

	isOrderDateValidForReshipment = (orderDate: Date) => {
		return this.api.get("/reshipment/validateOrderDate", {
			params: {orderDate}
		})
			.then(response => response.data);
	};

	validateBatchPriceForReshipment = (itemBatchPrice: number) => {
		return this.api.get("/reshipment/validateOrderItemPrice", {
			params: {itemBatchPrice}
		})
			.then(response => response.data);
	};
}

const reshipmentService = new ReshipmentService();
export default reshipmentService;
