import proposalGuideTimeSearch, {ProposalMaintenanceGuideTimeSearchState} from "./proposalMaintenanceGuideTimeSearch";
import vehicleGuideTimeSearch, {VehicleMaintenanceGuideTimeSearch} from "./vehicleMaintenanceGuideTimeSearch";
import {combineReducers} from "redux";
import type {MaintenanceBundleState} from "./maintenanceBundle";
import maintenanceBundle from "./maintenanceBundle";

const rootReducer = combineReducers({
	proposalGuideTimeSearch,
	vehicleGuideTimeSearch,
	maintenanceBundle
});

export type MaintenanceGuideTimeSearchState = {
	proposalGuideTimeSearch: ProposalMaintenanceGuideTimeSearchState,
	vehicleGuideTimeSearch: VehicleMaintenanceGuideTimeSearch,
	maintenanceBundle: MaintenanceBundleState
}

export default rootReducer;